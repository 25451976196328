import {str_replace} from '../General/General';

const KEY_CONST = 'lrd.mx';

const Base64 = {
	_keyStr: "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/=", encode: function (e) {
		var t = "";
		var n, r, i, s, o, u, a;
		var f = 0;
		e = Base64._utf8_encode(e);
		while (f < e.length) {
			n = e.charCodeAt(f++);
			r = e.charCodeAt(f++);
			i = e.charCodeAt(f++);
			s = n >> 2;
			o = (n & 3) << 4 | r >> 4;
			u = (r & 15) << 2 | i >> 6;
			a = i & 63;
			if (isNaN(r)) {
				u = a = 64
			} else if (isNaN(i)) {
				a = 64
			}
			t = t + this._keyStr.charAt(s) + this._keyStr.charAt(o) + this._keyStr.charAt(u) + this._keyStr.charAt(a)
		}
		return t
	}, decode: function (e) {
		var t = "";
		var n, r, i;
		var s, o, u, a;
		var f = 0;
		e = e.replace(/[^A-Za-z0-9\+\/\=]/g, "");
		while (f < e.length) {
			s = this._keyStr.indexOf(e.charAt(f++));
			o = this._keyStr.indexOf(e.charAt(f++));
			u = this._keyStr.indexOf(e.charAt(f++));
			a = this._keyStr.indexOf(e.charAt(f++));
			n = s << 2 | o >> 4;
			r = (o & 15) << 4 | u >> 2;
			i = (u & 3) << 6 | a;
			t = t + String.fromCharCode(n);
			if (u !== 64) {
				t = t + String.fromCharCode(r)
			}
			if (a !== 64) {
				t = t + String.fromCharCode(i)
			}
		}
		t = Base64._utf8_decode(t);
		return t
	}, _utf8_encode: function (e) {
		e = e.replace(/\r\n/g, "\n");
		var t = "";
		for (var n = 0; n < e.length; n++) {
			var r = e.charCodeAt(n);
			if (r < 128) {
				t += String.fromCharCode(r)
			} else if (r > 127 && r < 2048) {
				t += String.fromCharCode(r >> 6 | 192);
				t += String.fromCharCode(r & 63 | 128)
			} else {
				t += String.fromCharCode(r >> 12 | 224);
				t += String.fromCharCode(r >> 6 & 63 | 128);
				t += String.fromCharCode(r & 63 | 128)
			}
		}
		return t
	}, _utf8_decode: function (e) {
		var c1, c2, c3;
		var t = "";
		var n = 0;
		var r = c1 = c2 = 0;
		while (n < e.length) {
			r = e.charCodeAt(n);
			if (r < 128) {
				t += String.fromCharCode(r);
				n++
			} else if (r > 191 && r < 224) {
				c2 = e.charCodeAt(n + 1);
				t += String.fromCharCode((r & 31) << 6 | c2 & 63);
				n += 2
			} else {
				c2 = e.charCodeAt(n + 1);
				c3 = e.charCodeAt(n + 2);
				t += String.fromCharCode((r & 15) << 12 | (c2 & 63) << 6 | c3 & 63);
				n += 3
			}
		}
		return t
	}
};

function str_invert(cadena) {
	var x = cadena.length;
	var cadenaInvertida = "";
	
	while (x >= 0) {
		cadenaInvertida = cadenaInvertida + cadena.charAt(x);
		x--;
	}
	return cadenaInvertida;
}

function str_encode(str, scape) {
	if (scape === true) {
		return Base64.encode(unescape(encodeURIComponent(str)));
	} else {
		return Base64.encode(str);
	}
}

function str_decode(str, scape) {
	if (scape === true) {
		return decodeURIComponent(escape(Base64.decode(str)));
	} else {
		return Base64.decode(str);
	}
}

function get_time_encode() {
	return str_encode((+new Date).toString()).split('==').join('');
}

function get_time_decode(s) {
	var x = s + '==';
	return new Date(Number(str_decode(x)));
}

function amigable(cadena) {
	var a = getCleanedString(quitaacentos(cadena));
	a = str_replace(' ', '-', a);
	a = str_replace('&', '-', a);
	a = str_replace('\r\n', '-', a);
	a = str_replace('\n', '-', a);
	a = str_replace('+', '-', a);
	
	a = str_replace('/[^a-z0-9\-<>]/', '-', a);
	a = str_replace('/[\-]+/', '-', a);
	a = str_replace('/<[^>]*>/', '-', a);
	
	return a;
}

function setURLs_Id(arr_url, id) {
	var cadena = arr_url.join(' ');
	// var array_cadena = (cadena || '').split(' ');
	// for (var i = 0; i < array_cadena.length; i++) {
	// 	array_cadena[i] = amigable(array_cadena[i]);
	// }
	// cadena = array_cadena.join(' ');
	// Definimos los caracteres que queremos eliminar
	var specialChars = "!@#$^&%*()+=-[]\/{}|:<>?,.";
	
	// Los eliminamos todos
	for (var i = 0; i < specialChars.length; i++) {
		cadena = cadena.replace(new RegExp("\\" + specialChars[i], 'gi'), '');
	}
	
	// Lo queremos devolver limpio en minusculas
	cadena = cadena.toLowerCase();
	
	// Quitamos espacios y los sustituimos por - porque nos gusta mas asi
	cadena = cadena.replace(/ /g, "-");
	
	// Quitamos acentos y "ñ". Fijate en que va sin comillas el primer parametro
	cadena = cadena.replace(/á/gi, "a");
	cadena = cadena.replace(/é/gi, "e");
	cadena = cadena.replace(/í/gi, "i");
	cadena = cadena.replace(/ó/gi, "o");
	cadena = cadena.replace(/ú/gi, "u");
	cadena = cadena.replace(/ñ/gi, "n");
	return cadena + '-' + keyTxt() + str_invert(str_encode(id, true)) + str_invert(keyTxt()) + get_time_encode();
}

function getURLs_Id(value) {
	var a = (value || '').toString().split(keyTxt());
	var b = a[a.length - 1];
	var c = (b || '').toString().split(str_invert(keyTxt()));
	var d = str_invert(c[0]);
	var n = str_decode(d);
	return n;
}

function keyTxt() {
	return str_encode(KEY_CONST, true);
}

function getCleanedString(cadena) {
	var specialChars = "!@#$^&%*()+=-[]\/{}|:<>?,.";
	
	for (var i = 0; i < specialChars.length; i++) {
		cadena = cadena.replace(new RegExp("\\" + specialChars[i], 'gi'), '');
	}
	
	cadena = cadena.toLowerCase();
	cadena = cadena.replace(/ /g, " ");
	cadena = cadena.replace(/á/gi, "a");
	cadena = cadena.replace(/é/gi, "e");
	cadena = cadena.replace(/í/gi, "i");
	cadena = cadena.replace(/ó/gi, "o");
	cadena = cadena.replace(/ú/gi, "u");
	cadena = cadena.replace(/ñ/gi, "n");
	return cadena;
}

function quitaacentos(t) {
	let á = "a";
	let é = "e";
	let í = "i";
	let ó = "o";
	let ú = "u";
	let ñ = "n";
	let ä = "a";
	let ë = "e";
	let ï = "i";
	let ö = "o";
	let ü = "u";
	let acentos = /[áéíóúñäëïöü]/g;
	return t.replace(acentos, function ($1) {
		return eval($1);
	});
}

const FriendlyURLsId = {
	setURLs_Id: setURLs_Id,
	getURLs_Id: getURLs_Id,
	str_invert: str_invert,
	str_encode: str_encode,
	str_decode: str_decode,
	get_time_encode: get_time_encode,
	get_time_decode: get_time_decode
};

export default FriendlyURLsId;
