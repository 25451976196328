import React, {Component, Fragment, useState} from 'react';
import PropTypes from 'prop-types';

import Button from '@material-ui/core/Button/index';
import TextField from '@material-ui/core/TextField/index';
import Dialog from '@material-ui/core/Dialog/index';
import DialogActions from '@material-ui/core/DialogActions/index';
import DialogContent from '@material-ui/core/DialogContent/index';
import DialogTitle from '@material-ui/core/DialogTitle/index';
import Grid from "@material-ui/core/Grid";

import Divider from "@material-ui/core/Divider";
import DraggableModal from "./DraggableModal";
import Slide from "@material-ui/core/Slide";
import {FieldsJs} from "../service/General/General";
import emailjs from "emailjs-com";
import "./contacto.css"
import {PerfilService as ContactoService} from "../../services/ContactoService";
import {IntegrationNotistack, ShowSnackBarsNotistack} from "../../utils/IntegrationNotistack/IntegrationNotistack";



class ModalContacto extends Component {
	
	state = {};
	
	constructor(props) {
		super(props);
		
		this.state = {
			modal: {
				open: false
			},
			
			tipo: props.tipo,
			whatsapp: (props.item || 0).whatsapp,
			nombre: '',
			correo: '',
			telefono: props.telefono || '',
			mensaje: '',
		};
	}
	
	
	handleChange = (e, variable, campo, date, input) => {
		FieldsJs.HandleChange(e, variable, campo, date, input, (r) => this.setState({
			[r.name]: r.value
		}))
	};
	
	showSnackBars = (type, message) => {
		ShowSnackBarsNotistack(this.props, type, message, null);
	};
	
	
	closeModal = () => {
		this.setState({
			modal: {
				open: false
			}
		});
	};
	
	close = () => {
		this.closeModal();
	};
	
	openModal = () => {
		const props = this.props;
		this.setState({
			modal: {
				open: true
			}
		});
		console.log(this.state, props);
	};
	
	open = () => {
		this.init().then(r => {
			setTimeout(() => this.openModal());
		}).catch(e => {
			console.log(e);
		});
	};
	
	RefreshInit = () => {
		this.init();
	};
	
	init = () => {
		if (this.state.whatsapp) {
			return new Promise((resolve, reject) => {
				ContactoService.Whatsapp(this.state).then((response) => {
					this.setState({
						tipo: 'view'
					});
					resolve(true);
				}).catch((error) => {
					console.log(error.mensaje);
					this.showSnackBars('error', error.mensaje);
					reject(false);
				});
			});
			
		} else {
			return new Promise((resolve, reject) => {
				resolve(true);
				
			});
		}
	};
	
	cleanForm = () => {
		this.setState({
			nombre: '',
			correo: '',
			telefono: '',
			mensaje: '',
		});
	};
	
	save = () => {
		ContactoService.NuevoContacto(this.state).then((response) => {
			this.setState({
				tipo: 'view'
			});
			this.cleanForm();
		}).catch((error) => {
			this.showSnackBars('error', error.mensaje);
		});
	};
	
	add = () => {
		console.log(this.props);
		const {id_producto, item} = this.props;
		
		this.setState({
			tipo: this.props.tipo,
			whatsapp: (this.props.item || 0).whatsapp,
			nombre: '',
			correo: '',
			telefono: '',
			mensaje: '',
		});
		this.open();
	};
	
	edit = () => {
		const {id_producto, item} = this.props;
		console.log(this.props);
		this.setState({
			tipo: this.props.tipo,
			whatsapp: (this.props.item || 0).whatsapp,
			nombre: '',
			correo: '',
			telefono: '',
			mensaje: '',
		});
		this.open();
	};
	
	view = () => {
		const {id_producto, item} = this.props;
		console.log(this.props);
		this.setState({
			tipo: this.props.tipo,
			whatsapp: 1,
			nombre: '',
			correo: '',
			telefono: this.props.telefono || '',
			mensaje: '',
		});
		
		setTimeout(() => {
			this.open();
		}, 800)
		
	};
	
	
	render(formStyle) {
		
		let funcion;
		
		switch (this.state.tipo) {
			case 'edit':
				funcion = this.edit;
				break;
			case 'add':
				funcion = this.add;
				break;
			case 'view':
				funcion = this.view;
				break;
			default:
				funcion = () => {
				};
		}
		
		const result = false;
		const showresult = false;
		
		const Result = () => {
			return (
				<p className="success-message">Your Message has been successfully sent. I will contact you soon.</p>
			)
		}
		
		
		const sendEmail = (e) => {
			e.preventDefault();
			emailjs
				.sendForm(
					'service_p4x3hv8',
					'template_jgfr42f',
					e.target,
					'user_jrfTH2e0Ely35ZCVFdT9S'
				)
				.then((result) => {
						console.log(result.text);
					},
					(error) => {
						console.log(error.text);
					}
				);
			e.target.reset();
			showresult(true);
		};
		
		
		return (
			<Grid>
				
				<Fragment>
						<span
							style={{cursor: 'pointer'}}
							children={this.props.componente}
							onClick={() => funcion()}
						/>
				</Fragment>
				
				<Dialog open={this.state.modal.open} onClose={() => this.close()} PaperComponent={DraggableModal}
				        disableEscapeKeyDown disableBackdropClick maxWidth={'sm'} fullWidth={true} scroll={'paper'}
				        transition={<Slide direction="up"/>} aria-labelledby="scroll-dialog-title" className={'bg_black'}
				>
					
					<DialogTitle>
						{this.state.tipo !== 'add' ?
							(<h3 style={{margin: '0px', align: 'center'}}>¡Gracias por contactar con nosotros!</h3>)
							:
							(<h2 style={{margin: '0px'}}>Contáctanos</h2>)
						}
					</DialogTitle>
					
					<DialogContent className={'padding-20'}>
						
						<Grid container spacing={2}>
							{this.state.tipo !== 'add' ? (
									<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
										<div className="form-group" style={{align: 'center'}}>
											Le responderemos lo antes posible.
										</div>
									</Grid>
								) :
								(
									<Fragment>
										<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
											<TextField className="form-group" type={'text'} fullWidth name="nombre"
											           onChange={this.handleChange}
											           autoComplete={'off'}
											           value={this.state.nombre}
											           required
											           placeholder="Nombre / Apellido"
											           disabled={this.state.tipo === 'view'}
											/>
										</Grid>
										
										<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
											<TextField className="form-group" type={'text'} fullWidth name="correo"
											           onChange={this.handleChange}
											           autoComplete={'off'}
											           value={this.state.correo}
											           required
											           placeholder="Correo Electrónico"
											           disabled={this.state.tipo === 'view'}
											/>
										</Grid>
										
										<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
											<TextField className="form-group" type={'text'} fullWidth name="telefono"
											           onChange={this.handleChange}
											           autoComplete={'off'}
											           value={this.state.telefono}
											           required
											           placeholder="Teléfono"
											           disabled={this.state.tipo === 'view'}
											/>
										</Grid>
										
										<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
											<TextField className="form-group" type={'text'} fullWidth name="mensaje"
											           multiline rows={3}
											           onChange={this.handleChange}
											           autoComplete={'off'}
											           value={this.state.mensaje}
											           required
											           placeholder="Mensaje"
											           disabled={this.state.tipo === 'view'}
											/>
										</Grid>
									</Fragment>
								)
							}
						
						
						</Grid>
					
					</DialogContent>
					
					<Divider/>
					
					<DialogActions>
						<Grid container spacing={2}>
							{ this.state.tipo !== 'add' ? (
									<Grid item xs={12} sm={12} md={12} lg={12} xl={12} style={{textAlign: 'right'}}>
										<Button onClick={() => this.close()} className="btn-default">
											{'Cerrar'}
										</Button>
									</Grid>
						
								) : (
									<Fragment>
										<Grid item xs={12} sm={12} md={6} lg={6} xl={6} style={{textAlign: 'left'}}>
											<Button onClick={() => this.close()} className="btn-default">
												{'Cerrar'}
											</Button>
										</Grid>
										<Grid item xs={12} sm={12} md={6} lg={6} xl={6} style={{textAlign: 'right'}}>
											<Button onClick={() => this.save()} className="btn-default">
												{'Enviar'}
											</Button>
										</Grid>
									</Fragment>
								)
							}
						</Grid>
					</DialogActions>
				
				</Dialog>
			
			</Grid>
		);
	}
}

ModalContacto.propTypes = {
	tipo: PropTypes.string.isRequired,
	item: PropTypes.object.isRequired,
	telefono: PropTypes.number,
};

export default IntegrationNotistack(ModalContacto);
