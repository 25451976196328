import {HttpRequest} from '../utils/HttpRequest/HttpRequest';

export const PerfilService = {
	NuevoContacto: (form) => {
		let params = {
			whatsapp: 0,
			nombre: form.nombre,
			correo: form.correo,
			telefono: form.telefono,
			mensaje: form.mensaje,
			activo: 1
		};
		return new Promise((resolve, reject) => {
			HttpRequest.post('_Contacto_Agregar', params).then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	Whatsapp: (form) => {
		let params = {
			whatsapp: 1,
			telefono: form.telefono,
			activo: 1
		};
		return new Promise((resolve, reject) => {
			HttpRequest.post('_Whatsapp_Agregar', params).then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
};
