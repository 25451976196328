import React, {Component, Fragment, useState} from 'react';
import PropTypes from 'prop-types';
import {PerfilService as ContactoService} from "../../services/ContactoService";
import {FieldsJs} from "../../elements/service/General/General";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import {Link} from "react-router-dom";
import ModalProducto from "../../elements/portfolio/ModalContacto";
import IntegrationNotistack, {ShowSnackBarsNotistack} from "../../utils/IntegrationNotistack/IntegrationNotistack";
import { useSnackbar } from 'notistack';

class NewsletterOne extends Component {
	state = {};
	
	constructor(props) {
		super(props);
		
		this.state = {
			modal: {
				open: false
			},
			
			tipo: props.tipo,
			whatsapp:1,
			nombre: '',
			correo: '',
			telefono: '',
			mensaje: '',
		};
	}
	
	handleChange = (e, variable, campo, date, input) => {
		FieldsJs.HandleChange(e, variable, campo, date, input, (r) => this.setState({
			[r.name]: r.value
		}))
	};
	
	
	showSnackBars = (type, message) => {
		ShowSnackBarsNotistack(this.props, type, message, null);
	};
	
	cleanForm = () => {
		this.setState({
			telefono: ''
		});
	};
	
	save = () => {
		ContactoService.Whatsapp(this.state).then((response) => {
			this.setState({
				tipo: 'view'
			})
		}).catch((error) => {
			console.log(error);
			this.showSnackBars('error', error.mensaje);
		});
	};
	
	render(extraClass, newsletetrStyle) {
		
		return (
			<Fragment>
				
				
				<div className={`newsletter-area ${newsletetrStyle}`}>
					<div className="container">
						<div className={`row row--0 newsletter-wrapper align-items-center ${extraClass}`}>
							<div className="col-lg-4">
								<div className="newsletter-section-title">
									<h3 className="title">¿Quieres ser distribuidor?</h3>
									<p className="description">Solo dejanos tu número de Whatsapp y nos comunicaremos contigo.</p>
								</div>
							</div>
							<div className="col-lg-8">
								
								<Grid container spacing={1}>
									<Grid item xs={12} sm={12} md={10} lg={10} xl={10}>
										<TextField className="form-group" type={'text'} fullWidth name="telefono"
										           onChange={this.handleChange}
										           autoComplete={'off'}
										           value={this.state.telefono}
										           required
										           placeholder="Número de whatsapp"
										           disabled={this.state.tipo === 'view'}
										/>
									</Grid>
									
									<Grid item xs={12} sm={12} md={2} lg={2} xl={2}>
										<div className="form-group">
											<ModalProducto tipo={"view"} item={this.state} telefono={this.state.telefono} componente={(
												<div style={{margin: '0px 0px 0px 0px'}}>
													<button className="btn-default" onClick={() => {
													this.cleanForm();
													}}>Enviar</button>
												</div>
											)}
											/>
											
										</div>
									
									</Grid>
								</Grid>
							
							</div>
						</div>
					</div>
				</div>
			</Fragment>
		
		)
	}
}

export default NewsletterOne;
