const filters = [
    {
      id: 1,
      text: "Irin Pervin",
    },
    {
      id: 2,
      text: "Fatima Asrafy",
    },
    {
      id: 3,
      text: "John Dou",
    },
    {
      id: 4,
      text: "Jannin",
    }
];
export default filters;